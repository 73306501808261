<template>
    <div>
        <div class="mainList">
            <div class="mainList_content">
                <div class="mainList_content_operation">

                    <div class="mainList_content_operation-l">
                        <el-button size="small" icon="el-icon-plus" @click="add()">新增</el-button>
                    </div>
                    <div class="mainList_content_operation-c">

                    </div>
                    <div class="mainList_content_operation-r">
                        <div class="item icon iconfont iconrefresh" @click="getData"></div>
                    </div>

                </div>
                <div class="mainList_content_sub">
                    <el-table ref="multipleTable"
                        row-key="id"
                        :data="tableData"
                        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                        tooltip-effect="dark"
                        height="string"
                        :default-expand-all="false"
                        border
                        v-loading="loading"
                        header-cell-class-name="meterHeader"
                        :span-method="spanMethod"
                    >
                        <el-table-column label="镇街/宗地" width="130" prop="name" align="left">
                            <template slot-scope="scope">
                                {{  scope.row.name  }}（{{  scope.row.total  }}）
                            </template>
                        </el-table-column>
                        <el-table-column label="宗地基本情况标题" prop="landName" align="center"></el-table-column>
                        <!-- <el-table-column label="基本情况" prop="info" align="center"></el-table-column> -->
                        <el-table-column label="基本情况" align="center">
                            <template slot-scope="scope">
                                <div v-html="scope.row.info"></div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" fixed="right" align="center" width="200">
                            <template slot-scope="scope">
                                <el-button v-if="scope.row.children" type="text" @click="add(scope.row)">新增</el-button>
                                <template v-else>
                                    <el-button type="text" class="edit" @click="edit(scope.row)">编辑</el-button>
                                    <el-button type="text" class="del" @click="del(scope)">删除</el-button>
                                </template>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

            </div>
        </div>
        <Edit :refresh="refresh" :isShow.sync="showEditDialog" :options="options"></Edit>
    </div>
</template>
<script>
import mixinsList from '@/mixins/list'
export default {
    components: {
        Edit: () => import("./Edit.vue"),
    },
    mixins: [mixinsList],
    data() {
        return {
            api:{
                del:this.$api['admin/landUseInfo'].del,
            },
        };
    },
    created() {
        this.getData();
    },
    methods: {
        add(row) {
            if(row){
                this.options = {townId:row.name};
            }else {
                this.options = {};
            }

            this.showEditDialog = true;
        },
        spanMethod({row, column, rowIndex, columnIndex}) {
            if(row.children){
                if (columnIndex === 0) {
                    return [1, 3];
                } else if(columnIndex === 3){
                    return [1, 1];
                }else {
                    return [0,0];
                }
            }else {
                return [1,1];
            }
        },
        getData(){
            this.loading = true;
            this.$api['admin/landUseInfo'].findByList().then(res => {

                this.loading = false;
                var obj = {};
                var arr = [];
                res.data.some(r=>{
                    if(!obj[r.town_id]){
                        obj[r.town_id] = {
                            id:r.town_id,
                            name:r.town_id,
                            children:[],
                            total:0,
                        }
                    }
                    obj[r.town_id].total += r.total;
                    obj[r.town_id].children.push({
                        id:r.id,
                        name:r.name,
                        landName:r.land_name,
                        info:r.info,
                        total:r.total,
                    })
                });

                for(let k in obj){
                    arr.push(obj[k])
                };
                
                this.tableData = arr;

            }).catch(err => {
                console.log(err);
                this.loading = false;
            });
        },
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";

</style>
